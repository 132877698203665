import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  Tabs,
  Tab,
} from '@mui/material';
import axios from 'axios';

const AddTags = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await axios.get('https://backend-cheeron.au-ki.com/onboard/restaurants');
        setRestaurants(response.data);
        if (response.data.length > 0) setSelectedRestaurant(response.data[0].id); // Default selection
      } catch (error) {
        showAlert('Error fetching restaurants');
      }
    };
    fetchRestaurants();
  }, []);

  const showAlert = (message) => {
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const handleGenerateTags = async () => {
    if (!selectedRestaurant) {
      showAlert('Please select a restaurant.');
      return;
    }

    const url = `https://backend-cheeron.au-ki.com/api/tag/${selectedRestaurant}`;

    try {
      const response = await axios.get(url);
      showAlert(`Tags generated successfully: ${response.data.message}`);
    } catch (error) {
      console.error('Error generating tags:', error);
      showAlert('Failed to generate tags.');
    }
  };

  return (
    <Box sx={{ mt: 2, width: '100%' }}>
      <FormControl sx={{ mb: 2, width: '25%' }}>
        <InputLabel>Restaurant</InputLabel>
        <Select
          value={selectedRestaurant}
          onChange={(e) => setSelectedRestaurant(e.target.value)}
        >
          {restaurants.map((restaurant) => (
            <MenuItem key={restaurant.id} value={restaurant.id}>
              {restaurant.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGenerateTags}
        sx={{ mb: 2, ml: 2 }}
      >
        Generate Tags
      </Button>

      <Snackbar open={openAlert} autoHideDuration={3000} onClose={() => setOpenAlert(false)}>
        <Alert onClose={() => setOpenAlert(false)} severity="info" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddTags;
