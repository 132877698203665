import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, ThemeProvider, createTheme, AppBar, Toolbar, Tabs, Tab, CssBaseline } from '@mui/material';
import MenuItemUpdate from './MenuItemUpdate';
import RestaurantForm from './RestaurantForm';
import AvailabilityUpdate from './AvailabilityUpdate';
import CategoryManagement from './CategoryManagement';
import AddMenuItems from './AddMenuItems.js'; 
import AddCategory from './AddCategory';
import AddTags from './AddTags';


const theme = createTheme({
  palette: {
    primary: { main: '#1976d2' },
    secondary: { main: '#f50057' },
    background: { default: '#f5f5f5' },
  },
  typography: {
    h4: { fontWeight: 600, marginBottom: '20px' },
    body1: { color: '#4a4a4a' },
  },
});

const App = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [menuTab, setMenuTab] = useState(0); 


  const handleTabChange = (event, newValue) => setActiveTab(newValue);
  const handleMenuTabChange = (event, newValue) => setMenuTab(newValue);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <AppBar position="static" color="primary">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }} align="center">
              SUPPORT DASHBOARD
            </Typography>
          </Toolbar>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            textColor="inherit"
            indicatorColor="secondary"
          >
            <Tab label="Restaurant" />
            <Tab label="Menu Management" />
          </Tabs>
        </AppBar>

        <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: theme.palette.background.default }}>
          <Container maxWidth="xl">
            {activeTab === 0 && <RestaurantForm />}
            {activeTab === 1 && (
              <Box>
                <Tabs
                  value={menuTab}
                  onChange={handleMenuTabChange}
                  centered
                  textColor="inherit"
                  indicatorColor="secondary"
                  sx={{ marginBottom: 2 }}
                >
                  <Tab label="Update Menu Item" />
                  <Tab label="Add Menu Items" />
                  <Tab label="Availability" />
                  <Tab label="Category" /> 
                  <Tab label="Add Category" />
                  <Tab label="Add Tags" />
                </Tabs>
                {menuTab === 0 && <MenuItemUpdate />}
                {menuTab === 1 && <AddMenuItems />}
                {menuTab === 2 && <AvailabilityUpdate />}
                {menuTab === 3 && <CategoryManagement />}
                {menuTab === 4 && <AddCategory />}
                {menuTab === 5 && <AddTags />}
              </Box>
            )}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default App;
