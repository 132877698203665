import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Switch,
  Snackbar,
  Alert,
  IconButton,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const AddCategory = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [categories, setCategories] = useState([
    { name: '', isActive: true, sortOrder: 1 },
  ]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const generateId = (title, restaurantId) => {
    let id = title
      .split(' ')
      .map((word, index) =>
        index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join('');
    return `${id}-${restaurantId}`;
  };

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await axios.get(
          'https://backend-cheeron.au-ki.com/onboard/restaurants'
        );
        setRestaurants(response.data);
      } catch (error) {
        showAlert('Error fetching restaurants.');
      }
    };
    fetchRestaurants();
  }, []);

  const showAlert = (message) => {
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const handleAddRow = () => {
    setCategories([...categories, { name: '', isActive: true, sortOrder: 1 }]);
  };

  const handleDeleteRow = (index) => {
    setCategories(categories.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, field, value) => {
    const updatedCategories = [...categories];
    updatedCategories[index][field] = value;
    setCategories(updatedCategories);
  };

  const handleAddCategories = async () => {
    if (!selectedRestaurant) {
      showAlert('Please select a restaurant.');
      return;
    }

    const payload = categories.map((cat) => ({
      id: generateId(cat.name, selectedRestaurant),
      title: cat.name,
      active: cat.isActive,
      sort_order: cat.sortOrder,
      restaurantId: selectedRestaurant,
    }));
    console.log(payload);

    try {
      const response = await axios.post(
        'https://backend-cheeron.au-ki.com/update/add-category',
        { categories: payload }
      );
      showAlert(response.data.message || 'Categories added successfully!');
      setCategories([{ name: '', isActive: true, sortOrder: 1 }]);
      setSelectedRestaurant('');
    } catch (error) {
      showAlert('Failed to add categories.');
    }
  };

  const handleCloseAlert = () => setOpenAlert(false);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
      <FormControl fullWidth>
        <InputLabel>Restaurant</InputLabel>
        <Select
          value={selectedRestaurant}
          onChange={(e) => setSelectedRestaurant(e.target.value)}
          sx={{ width: '30%' }}
        >
          {restaurants.map((restaurant) => (
            <MenuItem key={restaurant.id} value={restaurant.id}>
              {restaurant.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {categories.map((category, index) => (
          <Box key={index} display="flex" gap={2} alignItems="center">
            <TextField
              label="Category Name"
              variant="outlined"
              value={category.name}
              onChange={(e) => handleInputChange(index, 'name', e.target.value)}
              sx={{ width: '30%' }}
            />
            <TextField
              label="Sort Order"
              type="number"
              variant="outlined"
              value={category.sortOrder}
              onChange={(e) => handleInputChange(index, 'sortOrder', parseInt(e.target.value))}
              sx={{ width: '20%' }}
            />
            <Box display="flex" alignItems="center" gap={1}>
              <Typography>Status:</Typography>
              <Switch
                checked={category.isActive}
                onChange={(e) => handleInputChange(index, 'isActive', e.target.checked)}
                color="primary"
              />
              <Typography>{category.isActive ? 'Active' : 'Inactive'}</Typography>
            </Box>
            <IconButton onClick={() => handleDeleteRow(index)} color="error">
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        <Button
          startIcon={<AddCircleIcon />}
          onClick={handleAddRow}
          variant="outlined"
          sx={{ width: '20%' }}
        >
          Add Row
        </Button>
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={handleAddCategories}
        sx={{ width: '30%' }}
      >
        Add Categories
      </Button>

      <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="info" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddCategory;
