import React, { useState, useMemo } from 'react';
import { Box, FormControl, InputLabel, Select,Dialog,DialogTitle,DialogContent,DialogActions, MenuItem, Button, Typography, Drawer, List, ListItem, ListItemText } from '@mui/material';
import axios from 'axios';

const ExcelComponent = ({
  restaurants,
  restaurantId,
  setRestaurantId,
  isLoading,
  fetchAndDownloadExcel,
}) => {
  const [selectedExcelFile, setSelectedExcelFile] = useState(null); // For Excel upload
  const [selectedFiles, setSelectedFiles] = useState(null); // For S3 upload
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadingExcel, setUploadingExcel] = useState(false);
  const [activePanel, setActivePanel] = useState('Image'); // Track active side panel option
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  // Calculate restaurant name from restaurantId
  const restaurantName = useMemo(() => {
    const restaurant = restaurants.find((r) => r.id === restaurantId);
    return restaurant ? restaurant.name : '';
  }, [restaurantId, restaurants]);

  // Handler for file selection for S3 upload
  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  // Handler for Excel file selection for updating database
  const handleExcelFileChange = (e) => {
    setSelectedExcelFile(e.target.files[0]);
  };

  const handleUploadSubmit = async () => {
    if (!selectedFiles) {
      alert("Please select files to upload");
      return;
    }
  
    setUploadLoading(true);
  
    const formData = new FormData();
    for (const file of selectedFiles) {
      formData.append('files', file);
    }
  
    try {
      const response = await axios.post(
        'https://backend-cheeron.au-ki.com/update/upload-folder',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          responseType: 'blob', // Ensure the response is treated as a binary file
        }
      );
  
      // Create a downloadable link for the file
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
  
      // Handle file name from Content-Disposition or use fallback
      const contentDisposition = response.headers['content-disposition'];
      const fileName = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/"/g, '') // Extract file name
        : 'downloaded_file.xlsx'; // Fallback file name
  
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      alert('Files uploaded and downloaded successfully!');
    } catch (error) {
      console.error('Error uploading files:', error);
      alert('Error uploading files.');
    } finally {
      setUploadLoading(false);
    }
  };
  
  

  // Handle Excel file upload for updating menu items in the database
  const handleExcelUploadSubmit = () => {
    if (!selectedExcelFile) {
      alert("Please select an Excel file to upload.");
      return;
    }
    setOpenConfirmDialog(true);
  };

  const confirmExcelUpload = async () => {
    setUploadingExcel(true);
    setOpenConfirmDialog(false);

    const formData = new FormData();
    formData.append('file', selectedExcelFile);

    try {
      const endpoint =
        activePanel === 'Image'
          ? `https://backend-cheeron.au-ki.com/update/update-menu-items-with-excel`
          : `https://backend-cheeron.au-ki.com/update/upload-price-excel`;

      const response = await axios.post(
        `${endpoint}?restaurantId=${restaurantId}`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      alert(response.data.message || 'Excel data processed successfully!');
    } catch (error) {
      alert('Error updating menu items with Excel data.');
    } finally {
      setUploadingExcel(false);
    }
  };

  // Handle download of menu data (Price and Cuisine)
  const handleDownloadMenuData = async () => {
    if (!restaurantId) {
      alert("Please select a restaurant");
      return;
    }
  
    try {
      const response = await axios.get(
        `https://backend-cheeron.au-ki.com/update/download-menu-data`,
        {
          params: { restaurantId },
          responseType: 'blob', // Important for handling binary data
        }
      );
  
      // Create Blob for file download
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
  
      // Create a downloadable link
      const link = document.createElement('a');
      const contentDisposition = response.headers['content-disposition'];
      const fileName = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/"/g, '')
        : 'menu_data.xlsx';
  
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      alert('Error downloading menu data.');
    }
  };
  

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Side Panel */}
      <Drawer variant="permanent" anchor="left" sx={{ display: 'flex', justifyContent: 'center' }}>
  <List>
    <ListItem
      button
      onClick={() => setActivePanel('Image')}
      sx={{
        backgroundColor: activePanel === 'Image' ? 'primary.light' : 'inherit',
        '&:hover': { backgroundColor: 'primary.main', color: 'white' },
      }}
    >
      <ListItemText primary="Image" />
    </ListItem>
    <ListItem
      button
      onClick={() => setActivePanel('Price and Cuisine')}
      sx={{
        backgroundColor: activePanel === 'Price and Cuisine' ? 'primary.light' : 'inherit',
        '&:hover': { backgroundColor: 'primary.main', color: 'white' },
      }}
    >
      <ListItemText primary="Price and Cuisine" />
    </ListItem>
  </List>
</Drawer>


      {/* Main Content Based on Active Panel */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2, ml: 20, width: '100%' }}>
        {activePanel === 'Image' && (
          <>
            {/* Image Panel Content */}
            <FormControl style={{ width: '35%' }}>
              <InputLabel>Restaurant</InputLabel>
              <Select
                value={restaurantId}
                onChange={(e) => setRestaurantId(e.target.value)}
                disabled={!restaurants.length}
              >
                {restaurants.map((restaurant) => (
                  <MenuItem key={restaurant.id} value={restaurant.id}>
                    {restaurant.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              onClick={fetchAndDownloadExcel}
              disabled={!restaurantId || isLoading}
              sx={{ width: '30%' }}
            >
              Download Excel for Menu Items Without Images
            </Button>

            <Typography variant="h6">Upload Image Files</Typography>
            <input type="file" multiple onChange={handleFileChange} />
            <Button
              variant="contained"
              color="primary"
              onClick={handleUploadSubmit}
              disabled={uploadLoading || !selectedFiles}
              sx={{ width: '20%' }}
            >
              {uploadLoading ? 'Uploading...' : 'Upload Files'}
            </Button>

            {uploadedFileName && (
              <Typography variant="body1" sx={{ mt: 2 }}>
                Uploaded Excel File: {uploadedFileName}
              </Typography>
            )}

            <Typography variant="h6" sx={{ mt: 4 }}>Update Menu Items with Excel</Typography>
            <input type="file" accept=".xlsx, .xls" onChange={handleExcelFileChange} />
            <Button
              variant="contained"
              color="primary"
              onClick={handleExcelUploadSubmit}
              disabled={uploadingExcel || !selectedExcelFile}
              sx={{ width: '20%' }}
            >
              {uploadingExcel ? 'Uploading...' : 'Upload Excel File'}
            </Button>
          </>
        )}

        {activePanel === 'Price and Cuisine' && (
          <>
            {/* Price and Cuisine Panel Content */}
            <FormControl style={{ width: '35%' }}>
              <InputLabel>Restaurant</InputLabel>
              <Select
                value={restaurantId}
                onChange={(e) => setRestaurantId(e.target.value)}
                disabled={!restaurants.length}
              >
                {restaurants.map((restaurant) => (
                  <MenuItem key={restaurant.id} value={restaurant.id}>
                    {restaurant.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadMenuData}
              disabled={!restaurantId || isLoading}
              sx={{ width: '20%' }}
            >
              Download Menu Data
            </Button>

            <Typography variant="h6" sx={{ mt: 4 }}>Update Price and Cuisine with Excel</Typography>
            <input type="file" accept=".xlsx, .xls" onChange={handleExcelFileChange} />
            <Button
              variant="contained"
              color="primary"
              onClick={handleExcelUploadSubmit}
              disabled={uploadingExcel || !selectedExcelFile}
              sx={{ width: '20%' }}
            >
              {uploadingExcel ? 'Uploading...' : 'Upload Excel File'}
            </Button>
          </>
        )}

<Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
          <DialogTitle>Confirm Upload</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to upload this Excel file?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirmDialog(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={confirmExcelUpload} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default ExcelComponent;
